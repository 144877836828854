import React, { useEffect, useState } from "react";
import { Table, Button, Image, Row, Col, Tooltip, Space, Tag ,message} from "antd";
import FilterModal from "../FilterModal";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import { useDispatch, useSelector } from "react-redux";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import { URL } from "../Helper/MainURL";
import PapaParse from "papaparse";
import { DownloadOutlined } from "@ant-design/icons";


const columns = [
  {
    title: "",
    dataIndex: "BRANDNAME",
    key: "BRANDNAME",
    width: 400,
    render: (item) => {
      return <> {item} </>;
    },
  },
  {
    title: "Visits change vs a year ago",
    dataIndex: "PercentChange_visits",
    key: "PercentChange_visits",
    render: (item) => {
      let x = Math.sign(item);
      let value = Number(item);

      if (x >= 0) {
        return (
          <>
            <span style={{ backgroundColor: "#4FE7B1", width: value <= 130 ? `${value}px` : "130px", position: "absolute", height: "18px", borderRadius: "0px 10px 10px 0px", display: "inline-block", marginLeft: `-16px` }}> </span>{" "}
            <span style={{ marginLeft: value <= 130 ? `${value}px` : "130px" }}>{item} %</span>
          </>
        );
      } else {
        return (
          <>
            <span style={{ backgroundColor: "#4FE7B1", width: `${Math.abs(value)}px`, position: "absolute", height: "18px", borderRadius: "10px 0px 0px 10px", marginLeft: `${value - 15}px` }}> </span>{" "}
            <span style={{ marginLeft: `-15px` }}>{item} %</span>{" "}
          </>
        );
      }
    },
  },
  {
    title: "Market share",
    dataIndex: "share",
    key: "share",
    render: (item) => {
      let x = Math.sign(item);
      let value = Number(item);

      if (x >= 0) {
        return (
          <>
            <span style={{ backgroundColor: "#F76870", width: value <= 130 ? `${value}px` : "130px", position: "absolute", height: "18px", borderRadius: "0px 10px 10px 0px", display: "inline-block", marginLeft: `-16px` }}> </span>{" "}
            <span style={{ marginLeft: value <= 130 ? `${value}px` : "130px" }}>{item}%</span>
          </>
        );
      } else {
        return (
          <>
            <span style={{ backgroundColor: "#F76870", width: `${Math.abs(value)}px`, position: "absolute", height: "18px", borderRadius: "10px 0px 0px 10px", marginLeft: `${value - 15}px` }}> </span>{" "}
            <span style={{ marginLeft: `-15px` }}>{item}%</span>{" "}
          </>
        );
      }
    },
  },
  {
    title: "Market share change vs a year ago",
    dataIndex: "PercentChange",
    key: "PercentChange",
    render: (item) => {
      let x = Math.sign(item);
      let value = Number(item);
      if (x >= 0) {
        return (
          <>
            <span style={{ backgroundColor: "#FBB941", width: value <= 130 ? `${value}px` : "130px", position: "absolute", height: "18px", borderRadius: "0px 10px 10px 0px", display: "inline-block", marginLeft: `-16px` }}> </span>{" "}
            <span style={{ marginLeft: value <= 130 ? `${value}px` : "130px" }}>{item}%</span>
          </>
        );
      } else {
        return (
          <>
            <span style={{ backgroundColor: "#FBB941", width: `${Math.abs(value)}px`, position: "absolute", height: "18px", borderRadius: "10px 0px 0px 10px", marginLeft: `${value - 15}px` }}> </span>{" "}
            <span style={{ marginLeft: `-15px` }}>{item}%</span>{" "}
          </>
        );
      }
    },
  },
];
const Snapshot = (props) => {
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const {Timescales, MarketSegment, CompetitiveSet,Region, DayTime } = filterValues;

  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [calApi, setCalApi] = useState(0);

  useEffect(() => {
    getData(filterValues);
  }, [calApi]);
  const getData = async (data) => {
    setLoader(true);
    let payload = {
      page_number: 1,
      filters: {
        TimeScale: data?.Timescales,
        TimePeriod: data?.TimePeriod,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Region: data?.Region,
        DayTime: data?.DayTime,
      },
      sort_column: "Competitive_Set",
      sort_type: "ASC",
      comparison_type: ["Vs Last Year"],
      email: localStorage.getItem("email"),
      country: localStorage.getItem("country"),
    };
    const randomParam = Math.random().toString(36).substring(7);

    await fetch(`${URL}/traffic/snapshot?${randomParam}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      cache: "default",
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          if (response.success == false) {
            message.error("Your session has timed out. Please refresh and log in again to continue")
      
            setTimeout(()=>{
              localStorage.clear();
              window.location.reload();
            },2000)
           
          }
          setData(data?.data);
          setLoader(false);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const getDownloadData = (data) => {
    // setloadingExport(true);
    downloadcsv(data);
    // console.log("downloadcsv::::::::",downloadcsv)
  };
  const downloadcsv = (data) => {
    // console.log("data-----",data)
    // return
      let LastLine1={BRANDNAME:"Copyright 2025.  Meaningful Vision . All Rights Reserved. Proprietary and Confidential"}
      data.push(LastLine1)
      let LastLine2={BRANDNAME:"Property of Meaningful Vision  and it's Affiliates. Licensed for Use by Meaningful Vision  Clients Only."}
      data.push(LastLine2)
    
   
    // setloadingExport(false);
    var csv = PapaParse.unparse(data);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download",  "Traffic-Snapshot");
    tempLink.click();
  };
  return (
    <>
      <Row className='mb-2'>
        <Col span={20} className='p-1'></Col>
        <Col span={4} className='p-1 text-center'>
          <Button block onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"} style={{width:120}}>
            Filters
          </Button>
          {" "}
          <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                // loading={loadingExport}
                onClick={() => {
                 getDownloadData(data);
                }}
              ></Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Space size={[0, "small"]} wrap>
          <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : " All"}>
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Timescales:
              {Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              MarketSegment && MarketSegment.length > 0
                ? MarketSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Segment:
              {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              CompetitiveSet && CompetitiveSet.length > 0
                ? CompetitiveSet.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Competitive:
              {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              Region && Region.length ? Region[0]  :    Region && Region.length > 0
                ? Region.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Region:
              {Region && Region.length > 0 ? `${Region[0]} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
          DayTime && DayTime.length ? DayTime[0] :   DayTime && DayTime.length > 0
                ? DayTime.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Day Time:
              {DayTime && DayTime.length > 0 ? `${DayTime[0]} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
        </Space>
      </Row>
      <Table
        dataSource={data}
        columns={columns}
        bordered
        pagination={false}
        scroll={{
          x: 920,
          y: "80vh",
        }}
        loading={loader}
      />
      <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} closeIcon={null} setCalApi={setCalApi} calApi={calApi} name={"snapshotTarrif"} visibility={false} dashboard={"Visits"}/>
    </>
  );
};

export default Snapshot;
