import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Row, Col, Button, Image, Tabs, Divider, theme, Radio, Pagination, Tooltip, notification, message } from "antd";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import { DownloadOutlined } from "@ant-design/icons";
import FilterModal from "../FilterModal";
import { useSelector, useDispatch } from "react-redux";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import PapaParse from "papaparse";
import { useLocation } from "react-router-dom";
import { URL } from "../Helper/MainURL";


const Label = ["", "Price by Region", "Prices by Channel", "Prices Variation"];

const Snapshot = (props) => {
  const location=useLocation()
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || "1");
  const [data, setData] = useState([]);
  const [calApi, setCalApi] = useState(0);

  const [selectedTab, setSelectedTab] = useState("region");
  const [currentPage, setCurrentPage] = useState(1);

  const { loader } = useSelector((state) => state.loader);
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [totalPage, setTotalPage] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [loadingExport, setloadingExport] = useState(false);
  const [loading4, setloading4] = useState(false);

  const [timeScaleLocal, setTimeScaleLocal] = useState("");
  const [sorter, setSorter] = useState({
    columnKey: "Brand",
    order: "ascend",
  });
  const { City, Timescales, MarketSegment, CompetitiveSet, Category, ProteinType, Channel, PriceRange, Item, loading ,benchmark,ProductSize,PriceSegment} = filterValues;

  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    getData(filterValues);
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };

  useEffect(() => {
    setCurrentPage(1);
    getData(filterValues);
  }, [calApi]);
  useEffect(() => {
    getData(filterValues);
  }, [activeTab, currentPage, sorter]);

  const onChangeTab = (e) => {
    setSorter({
      columnKey: "Brand",
      order: "ascend",
    });
    setCurrentPage(1);
    if (e == 1 || e == "1") {
      setSelectedTab("region");
    } else if (e == 2 || e == "2") {
      setSelectedTab("channel");
    } else {
      setSelectedTab("variation");
    }
    setActiveTab(e);
    return;
  };
  async function getData(data) {
    setloading4(true);

    var payloadregion = {
      page_number: currentPage,
      filters: {
        Timescale: data?.Timescales,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        City: data?.City,
        Item: data?.Item,
        Channel: data?.Channel,
        Size:data?.ProductSize,
        Price_range:data?.PriceSegment,
        benchmark:data?.benchmark,


      },
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      organization:localStorage.getItem("organization")

    };

    var payloadvari = {
      page_number: currentPage,
      filters: {
        Timescale: data?.Timescales,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        Item: data?.Item,
        Channel: data?.Channel,
        Size:data?.ProductSize,
        Price_range:data?.PriceSegment,
        benchmark:data?.benchmark,



      },
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      organization:localStorage.getItem("organization")

    };
    var payloadchannel = {
      page_number: currentPage,
      filters: {
        Timescale: data?.Timescales,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        Item: data?.Item,
        City: data?.City && data?.City.length ?  [data?.City[0]] : ['All'],
        Size:data?.ProductSize,
        Price_range:data?.PriceSegment,
        benchmark:data?.benchmark,


      },
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      organization:localStorage.getItem("organization")

    };
    const randomParam = Math.random().toString(36).substring(7);
    await fetch(`${URL}/snapshot/${selectedTab}?${randomParam}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },

      body: JSON.stringify(activeTab == 1 ? payloadregion : activeTab == 2 ? payloadchannel : payloadvari),
    })
      .then(function (response) {
        response.json().then(function (data) {
          if (response.success == false) {
            message.error("Your session has timed out. Please refresh and log in again to continue")
      
            setTimeout(()=>{
              localStorage.clear();
              window.location.reload();
            },2000)
           
          }
          setData(data.data);
          setTotalPage(data?.total_count);
          setTimeout(() => {
            setloading4(false);
          }, 2000);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }

  const getDownloadData = async (data) => {
    setloadingExport(true);

    openNotification("bottomLeft");
    var payloadregion = {
      dashboard_type: activeTab == 1 ? "Region" : activeTab == 2 ? "Channel" : "Variation",
      page_number: 1,
      filters: {
        Timescale: data?.Timescales,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        City: data?.City,
        Item: data?.Item,
        Channel: data?.Channel,
        Size:data?.ProductSize,
        Price_range:data?.PriceSegment,
        benchmark:data?.benchmark,

      },
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      organization:localStorage.getItem("organization")

    };

    var payloadvari = {
      dashboard_type: activeTab == 1 ? "Region" : activeTab == 2 ? "Channel" : "Variation",
      page_number: 1,
      filters: {
        Timescale: data?.Timescales,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        Item: data?.Item,
        Channel: data?.Channel,
        Size:data?.ProductSize,
        Price_range:data?.PriceSegment,
        benchmark:data?.benchmark,

      },
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      organization:localStorage.getItem("organization")

    };
    var payloadchannel = {
      dashboard_type: activeTab == 1 ? "Region" : activeTab == 2 ? "Channel" : "Variation",
      page_number: 1,
      filters: {
        Timescale: data?.Timescales,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        Item: data?.Item,
        City: data?.City && data?.City.length ?  [data?.City[0]] : ['All'],
        Size:data?.ProductSize,
        Price_range:data?.PriceSegment,
        benchmark:data?.benchmark,

      },
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      organization:localStorage.getItem("organization")

    };

    await fetch(`${URL}/exports/snapshotregion`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      body: JSON.stringify(activeTab == 1 ? payloadregion : activeTab == 2 ? payloadchannel : payloadvari),
    })
      .then(function (response) {
        response.json().then(function (data) {
          downloadcsv(data);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const downloadcsv = (data) => {
    if (!data.data || data.success == false || data?.data?.length == 0) {
      message.error("No data to download");
      setloadingExport(false);
      return;
    }
    var data1 = {
      fields: ["Product1", "Brand", "Belfast", "Birmingham", "Cardiff","Glasgow", "Leeds", "Liverpool", "London", "Manchester"],
      data: data?.data?.length > 0 ? data?.data : [],
    };
    var data2 = {
      fields: ["Product", "Brand", "Deliveroo", "Delivery_Average", "DineIn_Delivery", "Dine_In", "JustEat", "UberEats"],
      data: data?.data?.length > 0 ? data?.data : [],
    };
    var data3 = {
      fields: ["Product", "Brand", "MinPrice", "MaxPrice", "ModePrice", "AvgPrice", "Variation"],
      data: data?.data?.length > 0 ? data?.data : [],
    };
    let LastLine1={Product1:" Copyright 2025.  Meaningful Vision . All Rights Reserved. Proprietary and Confidential"}
   
    let LastLine2={Product1:"Property of Meaningful Vision  and it's Affiliates. Licensed for Use by Meaningful Vision  Clients Only."}
    let LastLine3={Product:"Copyright 2025.  Meaningful Vision. All Rights Reserved. Proprietary and Confidential"}
   
    let LastLine4={Product:"Property of Meaningful Vision  and it's Affiliates. Licensed for Use by Meaningful Vision  Clients Only."}
    let LastLine5={Product:"Copyright 2025.  Meaningful Vision. All Rights Reserved. Proprietary and Confidential"}
   
    let LastLine6={Product:"Property of Meaningful Vision  and it's Affiliates. Licensed for Use by Meaningful Vision  Clients Only."}
    if(activeTab == 1)
    {
      data1.data.push(LastLine1)
      data1.data.push(LastLine2)
    }
    else if(activeTab == 2){
      data2.data.push(LastLine3)
      data2.data.push(LastLine4)
    }
    else{
      data3.data.push(LastLine5) 
      data3.data.push(LastLine6)
    }
   
   
    
    
    var csv = PapaParse.unparse(activeTab == 1 ? data1 : activeTab == 2 ? data2 : data3);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${activeTab==1?"Price by Region":activeTab==2?"Prices by Channel":"Prices Variation"}.csv`);
    tempLink.click();
    setloadingExport(false);
  };

  const region = [
    {
      title: "Item",
      dataIndex: "Product1",
      key: "Product1",
      fixed: true,
      width: 300,
      sorter: true,
      render: (value, key) => {
        if (!value) {
          return <>-</>;
        }

        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Brand",
      dataIndex: "Brand",
      key: "Brand",
      fixed: true,
      width: 200,

      sorter: true,
      render: (value) => {
        if (!value) {
          return <>-</>;
        }

        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Belfast",
      dataIndex: "Belfast",
      key: "Belfast",
      width: 100,
      hidden: filterValues?.City?.includes("Belfast") || filterValues?.City?.includes("All") ? false : true,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Birmingham",
      dataIndex: "Birmingham",
      key: "Birmingham",
      width: 100,
      hidden: filterValues?.City?.includes("Birmingham") || filterValues?.City?.includes("All") ? false : true,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = arr.reduce((a, b) => Math.max(a, b));

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    // {
    //   title: "Bristol",
    //   dataIndex: "Bristol",
    //   key: "Bristol",
    //   width: 100,
    //   hidden: filterValues?.City?.includes("Bristol") || filterValues?.City?.includes("All") ? false : true,
    //   sorter: true,
    //   render: (value, records) => {
    //     delete records.Product;
    //     var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
    //     var min = Math.min(...arr);
    //     var max = arr.reduce((a, b) => Math.max(a, b));

    //     if (!value) {
    //       return <>-</>;
    //     }
    //     return (
    //       <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
    //         {value}
    //       </span>
    //     );
    //   },
    //   sorterIcon: ({ sortOrder }) => {
    //     if (sortOrder === "descend") {
    //       return <CaretDownOutlined />;
    //     }
    //     if (sortOrder === "ascend") {
    //       return <CaretUpOutlined />;
    //     }
    //     return <CaretUpOutlined />;
    //   },
    // },

    {
      title: "Cardiff",
      dataIndex: "Cardiff",
      key: "Cardiff",
      hidden: filterValues?.City?.includes("Cardiff") || filterValues?.City?.includes("All") ? false : true,
      width: 100,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Glasgow",
      dataIndex: "Glasgow",
      hidden: filterValues?.City?.includes("Glasgow") || filterValues?.City?.includes("All") ? false : true,

      key: "Glasgow",
      width: 100,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Leeds",
      dataIndex: "Leeds",
      hidden: filterValues?.City?.includes("Leeds") || filterValues?.City?.includes("All") ? false : true,

      key: "Leeds",
      width: 100,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Liverpool",
      dataIndex: "Liverpool",
      key: "Liverpool",
      hidden: filterValues?.City?.includes("Liverpool") || filterValues?.City?.includes("All") ? false : true,

      width: 100,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },

    {
      title: "London",
      dataIndex: "London",
      key: "London",
      hidden: filterValues?.City?.includes("London") || filterValues?.City?.includes("All") ? false : true,

      width: 100,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Manchester",
      dataIndex: "Manchester",
      key: "Manchester",
      hidden: filterValues?.City?.includes("Manchester") || filterValues?.City?.includes("All") ? false : true,
      width: 100,
      sorter: true,
      render: (value, records) => {
        delete records.Product;
        var arr = Object.values(records).filter((v) => v !== null && v !== "" && !isNaN(v));
        var min = Math.min(...arr);
        var max = Math.max(...arr);

        if (!value) {
          return <>-</>;
        }

        return (
          <span className={(value == max ? "colo-max" : "") || (value == min ? "colo-min" : "")} style={{ color: (value == max ? "#1DB9DE" : "") || (value == min ? "#9B6CFF" : "") }}>
            {value}
          </span>
        );
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
  ].filter((item) => !item.hidden);
  const channel = [
    {
      title: "Item",
      dataIndex: "Product",
      key: "Product",
      fixed: true,
      width: 200,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Brand",
      dataIndex: "Brand",
      key: "Brand",
      fixed: true,
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }

        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Dine In",
      dataIndex: "Dine_In",
      key: "Dine_In",
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Delivery Average",
      dataIndex: "Delivery_Average",
      key: "Delivery_Average",
      width: 120,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Delivery / DineIn %",
      dataIndex: "DineIn_Delivery",
      key: "DineIn_Delivery",
      width: 120,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value} %</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Uber Eats",
      dataIndex: "UberEats",
      key: "UberEats",
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Deliveroo",
      dataIndex: "Deliveroo",
      key: "Deliveroo",
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },

    {
      title: "JustEat",
      dataIndex: "JustEat",
      key: "JustEat",
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
  ];

  const variation = [
    {
      title: "Item",
      dataIndex: "Product",
      key: "Product",
      fixed: true,
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Brand",
      dataIndex: "Brand",
      key: "Brand",
      fixed: true,
      width: 100,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }

        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Min",
      dataIndex: "MinPrice",
      key: "MinPrice",
      width: 50,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Max",
      dataIndex: "MaxPrice",
      key: "MaxPrice",
      width: 50,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Mode",
      dataIndex: "ModePrice",
      key: "ModePrice",
      width: 50,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Average",
      dataIndex: "AvgPrice",
      key: "AvgPrice",
      width: 50,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Variation",
      dataIndex: "Variation",
      key: "Variation",
      width: 50,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
  ];
  const openNotification = (placement) => {
    api.info({
      message: `Downloading`,
      description: "While your file is being retrieved, feel free to continue using the tool.",
      placement,
    });
  };
  // console.log('City :::',City.length)
  return (
    <>
   
      {contextHolder}

      <div className='container-fluid'>
        <Row style={{ borderBottom: "1px solid #EAECF0" }} className='mb-4'>
          <Col span={12} className='p-1'>
            <span className='bg-white text-dark p-2 rounded'>
              <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
                {Label[activeTab]}
              </span>{" "}
              <span style={{ color: "#1DB9DE" }} className='fw-bold'>
                {" "}
                |
              </span>{" "}
              <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
                Table
              </span>
            </span>
          </Col>
          <Col span={9} className='text-center'></Col>

          <Col span={3} className='p-1 text-center'>
            <Space>
              <Button block onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
                Filters
              </Button>
              <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                loading={loadingExport}
                onClick={() => {
                  getDownloadData(filterValues);
                }}
              ></Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Space size={[0, "small"]} wrap>
            <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales : Timescales}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {Timescales && Timescales.length > 0 ? Timescales : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                MarketSegment && MarketSegment.length > 0
                  ? MarketSegment.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Segment:
                {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                CompetitiveSet && CompetitiveSet.length > 0
                  ? CompetitiveSet.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Competitive:
                {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                Category && Category.length > 0
                  ? Category.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Category:
                {Category && Category.length > 0 ? `${Category[0]}  ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                ProteinType && ProteinType.length > 0
                  ? ProteinType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                ProteinType:
                {ProteinType && ProteinType.length > 0
                  ? `${ProteinType[0]} 
             
              `
                  : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                Item && Item.length > 0
                  ? Item.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Item:
                {Item && Item.length > 0 ? `${Item[0]} ${Item?.length > 1 ? Item.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            {activeTab != 2 && (
              <Tooltip
                placement='bottomLeft'
                title={
                  Channel && Channel.length > 0
                    ? Channel.map((e) => {
                        return <li>{e}</li>;
                      })
                    : "Not selected"
                }
              >
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Channel:
                  {Channel && Channel.length > 0 ? `${Channel[0]}` : "Not selected"}
                </Tag>
              </Tooltip>
            )}
            {(activeTab == 2 || activeTab == 1) && (
              <Tooltip
                placement='bottomLeft'
                title={activeTab === 2 && City && City.length ? City[0] :
                  City && City.length > 0
                    ? City.map((e) => {
                        return <li>{e}</li>;
                      })
                    : "Not selected "
                }
              >
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  City:
                  {activeTab === 2 && City && City.length ? ` ${City[0]}`  :  City && City.length > 0 ? `${City[0]} ${City?.length > ` ${City[0]}` ? City.length : ""} ` : "Not selected"}{" "}
                </Tag>
              </Tooltip>
            )}
            <Tooltip
                placement='bottomLeft'
                title={ProductSize && ProductSize.length > 0
                  ? ProductSize.map((e) => {
                    return <li>{e}</li>;
                  })
                  : "Not selected"}
              >
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Product Size:
                  {ProductSize && ProductSize.length > 0 ? `${ProductSize[0]} ` : "Not selected"}{" "}
                </Tag>
              </Tooltip>
              <Tooltip
                placement='bottomLeft'
                title={PriceSegment && PriceSegment.length > 0
                  ? PriceSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                  : "Not selected"}
              >
                  <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Price Segment :
                    {PriceSegment && PriceSegment.length > 0 ? `${PriceSegment[0]} ` : "Not selected"}{" "}
                  </Tag>
                </Tooltip>
                <Tooltip
                placement='bottomLeft'
                title={benchmark && benchmark.length > 0
                  ? benchmark.map((e) => {
                    return <li>{e}</li>;
                  })
                  : "Not selected"}
              >
                  <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Benchmark :
                    {benchmark && benchmark.length > 0 ? `${benchmark[0]} ` : "Not selected"}{" "}
                  </Tag>
                </Tooltip>
          </Space>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          className='activetabcolor '
          style={{ textAlign: "center" }}
          onChange={onChangeTab}
          items={new Array(3).fill(null).map((_, i) => {
            return {
              label: <div className='text-center'>{Label[i + 1]}</div>,
              key: i + 1,
              children: (
                <>
                  <Table
                    columns={selectedTab == "variation" && activeTab == 3 ? variation : selectedTab == "channel" && activeTab == 2 ? channel : region}
                    dataSource={data}
                    loading={loading4}
                    pagination={false}
                    scroll={{
                      x: selectedTab == "variation" ? 450 : selectedTab == "region" ? 920 : 800,
                      y: "47vh",
                    }}
                    onChange={(val, filter, sorter, extra) => {
                      setSorter({
                        ...sorter,
                      });
                    }}
                  />
                  <div className='d-flex justify-content-end p-4'>
                    <Pagination
                      defaultCurrent={currentPage}
                      current={currentPage}
                      total={totalPage}
                      pageSize={20}
                      onChange={(e) => {
                        setCurrentPage(e);
                      }}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ),
            };
          })}
        />
      </div>
      {filterValues && <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} Label={Label} activeTab={activeTab} setCalApi={setCalApi} calApi={calApi} dashboard={"Prices"} name="Prices"/>}
    </>
  );
};
export default Snapshot;
