import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { setFilterValue } from "../../redux/filterValues";

function convertDateFormat(dateString) {
    if (!dateString) {
      dateString = ["dateString"];
    }
  
    const [monthStr, yearStr] = dateString && dateString != undefined ? dateString.split("-") : ["Apr-24"].split("-");
  
    const monthIndex = new Date(Date.parse(monthStr + " 1, " + yearStr)).getMonth();
  
    const daysInMonth = new Date(parseInt(yearStr), monthIndex + 1, 0).getDate();
  
    const month = (monthIndex + 1).toString().padStart(2, "0");
  
    const startDate = `20${yearStr}-${month}-01`;
    const endDate = `20${yearStr}-${month}-${daysInMonth}`;
  
    return [startDate, endDate];
  }
  function addDays(dateString, days) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return [dateString,`${year}-${month}-${day}`];
  }
 
const PromoByBrandChart = (props) => {
    const { setActiveTab, filterValues1, calApi } = props;

  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    
    if (props?.data?.sourcetype) {
      let tempArray = [];
      props.data.sourcetype.forEach((e) => {
        for (let i = 0; i < Object.keys(e).length; i++) {
          tempArray.push({
            name: Object.keys(e)[i],
            data: e[Object.keys(e)[i]],
          });
        }
      });
      setSeries(tempArray);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [props?.data?.sourcetype,props,calApi]);

  const handleBarClick = (event, chartContext, config) => {
    const category = config.w.config.xaxis.categories[config.dataPointIndex];
    const clickedSeries = config.w.config.series[config.seriesIndex].name;
    // const sourceType = clickedSeries ? clickedSeries.sourceType : undefined;
    // console.log("Clicked SourceType::::::::::::", category);

    // console.log("Clicked clickedSeries::::::::::::", clickedSeries);

    let Ts = localStorage.getItem("Timescales");
    let  Ms = localStorage.getItem("MarketSegment");
     let Cs = localStorage.getItem("CompetitiveSet");
    let  Cat = localStorage.getItem("Category");
      // Cha = localStorage.getItem("SourceType"),
    // let  City = localStorage.getItem("City");
    let  Pt1 = localStorage.getItem("PromoType");
    let  Pt2 = localStorage.getItem("PromoType2");
    let  TPromo=localStorage.getItem("TimePeriodPromo");
    let  TPromoWeekly=localStorage.getItem("TimescalesWeekly");
      // console.log("SourceType from localStorage:", Cha);
 
    var temp = new Array();
    if (Ts) {
      var dateTrend = convertDateFormat(Ts);
    } else {
      var dateTrend = convertDateFormat(...filterValues1.Timescales);
    }

    if (Ms) {
      var temp = new Array();
      temp = Ms.split(",");
    }
    if(TPromo=="Weekly")
      {
var newDate = addDays(TPromoWeekly, 6);

      }

    dispatch(
      setFilterValue({
        MarketSegment: [...temp],
        TimescalesTrend:TPromo=="Weekly"?[...newDate]:[...dateTrend],
        CompetitiveSet: [category],
        Category: Cat ? [Cat] : [],
        SourceType:  [clickedSeries],
        PromoType: Pt1 ? [Pt1] : [],
        PromoType2: Pt2 ? [Pt2] : [],
        City:["All"]
        

        
      })
    );
    setActiveTab(1);
  };
  const options = {
    chart: {
      type: "bar",
      // stackType: "100%",
      background: "#fff",
      stacked: true,
      events: {
        dataPointSelection: handleBarClick,   
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false
        },
        export: {
          png: {
            filename: "Meaningful Vision",
          }
        }
      },
    },
   

    dataLabels: {
      style: {
        colors: ["#000000"],
        fontWeight: "light",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
      },
    },

    xaxis: {
      categories: props?.data?.Channels ? props.data.Channels : [],
      lines: {
        show: true,
      },
    },
    yaxis: {
      stepSize:10,
  labels: {
    formatter: (val) => val.toFixed(0), // Optional: Format values
  },
      title: {
        text: "Active Promotions by Brand",
        style: {
          fontSize: "16px",
          fontWeight: "light",
          color: "#000000",
        },
      },

      lines: {
        show: true,
      },
    },

    tooltip: {
      y: {
        formatter: (val) => `${val} `,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    colors: ["#4e9ca4", "#f685a0", "#8ccfdd", "#53f5d1", "#ae8cf4", "#1db9de"],
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spin />
        </div>
      ) : (
        <>{props.data.Channels && <Chart options={options} series={series} type='bar' height={600} />}</>
      )}
    </div>
  );
};

export default PromoByBrandChart;
