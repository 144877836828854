import React, { useEffect, useState } from "react";
import { Table, Row, Col, Pagination, Modal, Button, Image, Space, Tooltip, Tag, Tabs, Switch,notification,message} from "antd";
import { useDispatch, useSelector } from "react-redux";

import { CaretUpOutlined, CaretDownOutlined, FilterOutlined } from "@ant-design/icons";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import FilterModal from "../FilterModal";
import Bar from "./Bar";
import StackedBarChart from "./BarStackType";
import { useLocation } from "react-router-dom";
import { URL } from "../Helper/MainURL";
import { DownloadOutlined } from "@ant-design/icons";
import PapaParse from "papaparse";


const Label = ["", "New Products", "Active Products", " Products by brand ", "Products by category, % "];


const Snapshot = (props) => {
  const location=useLocation();
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [modalflag, setModalFlag] = useState(false);
  const [img, setimg] = useState("");
  const [ImageName, setImageName] = useState("");
  const [calApi, setCalApi] = useState(0);
  const [loader1, setLoader1] = useState(true);
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 1);
  const [switchFlag, setswitchFlag] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const [sorter, setSorter] = useState({
    columnKey: "Item",
    order: "ascend",
  });
  const { City, TimescalesTrend, MarketSegment, CompetitiveSet, Category, ProteinType, Channel, PriceRange, Item, loading, Timescales,Offer } = filterValues;
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
    getProductsData();
  }, [calApi]);
  useEffect(() => {
    getProductsData();
  }, [currentPage, sorter, activeTab, switchFlag]);
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const getProductsData = async () => {
    setLoader1(true);

    let payLoad = {
      page_number: currentPage,
      filters: {
        Timescales: filterValues.TimescalesTrend,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Category: filterValues.Category,
        Protein_Type: filterValues.ProteinType,
        Offer:filterValues.Offer
      },
      dashboard_type: activeTab,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),

    };


    let payLoad2 = {
      filters: {
        Timescales: filterValues.Timescales,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Category: filterValues.Category,
        Protein_Type: filterValues.ProteinType,
        Offer:filterValues.Offer
      },
      line_dashboard: switchFlag == false ? 1 : 2,
      sort_column: "Chain",
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
    };
    let payLoad3 = {
      filters: {
        Timescales: filterValues.Timescales,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Offer:filterValues.Offer
      },

      sort_column: "Chain",
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
    };
    const randomParam = Math.random().toString(36).substring(7);

    await fetch(`${URL}/${activeTab == 1 || activeTab == 2 ? "product/" : activeTab == 3 ? "product-bar/" : "product-stack/"}?${randomParam}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activeTab == 3 ? payLoad2 : activeTab == 4 ? payLoad3 : payLoad),
    })
      .then(function (response) {
        response.json().then(function (data) {
          if (response.success == false) {
            message.error("Your session has timed out. Please refresh and log in again to continue")
      
            setTimeout(()=>{
              localStorage.clear();
              window.location.reload();
            },2000)
           
          }
          if (activeTab == 4 && typeof (data === "object") && data.promotype !== null) {
            setProductData(data);
          }
          if ((activeTab == 1 || activeTab == 3 || activeTab == 2) && Array.isArray(data?.data)) {
            setProductData(Array.isArray(data?.data) ? data.data : []);
            setTotalPage(data?.total_count);
          }
          setTimeout(() => {
            setLoader1(false);
          }, 3000);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };

  const columns = [
    {
      title: "Chain",
      dataIndex: "Chain",
      key: "Chain",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Protein Type",
      dataIndex: "ProteinType",
      key: "ProteinType",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Item ",
      dataIndex: "Item",
      key: "Item",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Prices ",
      dataIndex: "Prices",
      key: "Prices",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Picture ",
      dataIndex: "Picture",
      key: "Picture",
      render: (value, records) => {
        if (!value || value == "0.00") {
          return (
            <img
              src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${value}`}
              width='100'
              height='100'
              onClick={(e) => {
                setModalFlag(true);
                setimg(value);
                setImageName(records.Item);
              }}
            />
          );
        }
        return (
          <img
            src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${value}`}
            width='100'
            height='100'
            onClick={(e) => {
              setModalFlag(true);
              setimg(value);
              setImageName(records.Item);
            }}
          />
        );
      },
    },
  ];
  const onChangeSwitch = (checked) => {
    setswitchFlag(checked);
  };
  const onChangeTab = (tab) => {
    setCurrentPage(1);
    setActiveTab(tab);
  };


  const getDownloadData = (data) => {


    // setloadingExport(true);
    if (activeTab == 1 || activeTab == 2) {

    try{
        let payload = {
          page_number: currentPage,
          filters: {
            Timescales: filterValues?.TimescalesTrend,
            Market_Segment: filterValues?.MarketSegment,
            Competitive_Set: filterValues?.CompetitiveSet,
            Category: filterValues?.Category,
            Protein_Type: filterValues?.ProteinType,
            Offer:filterValues?.Offer
    
          },
          sort_column: activeTab == 1 ? sorter.columnKey : sorter.columnKey,
          sort_type: activeTab == 1 ? (sorter.order == "ascend" ? "ASC" : "DESC") : sorter.order == "ascend" ? "ASC" : "DESC",
          dashboard_type: activeTab,
          email: localStorage.getItem("email"),
        };
        fetch(`${URL}/exports/products-export`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          cache: "no-store",
          body: JSON.stringify(payload),
        })
          .then(function (response) {
            response.json().then(function (data) {
              setTimeout(() => {
                // console.log("data ::::::::::::::", data);
                downloadcsv(data.data);
               openNotification("bottomLeft");
              }, 2000);
            });
          })
          .catch((error) => {
            console.error("Fetch error:", error);
          });
        } catch(error){
          console.error("Fetch error:", error);
    
        }
      }
      else if (activeTab == 3 || activeTab  == 4){
        downloadcsv(data);

      }
    // console.log("downloadcsv::::::::",downloadcsv)
  };
  const downloadcsv = (data) => {
    // console.log("data-----", data);

    

    
    if (activeTab == 3) {
        data.push({ BrandName: "Copyright 2025. Meaningful Vision . All Rights Reserved. Proprietary and Confidential" });
        data.push({BrandName: "Property of Meaningful Vision and it's Affiliates. Licensed for Use by Meaningful Vision Clients Only."});
        
        var csv = PapaParse.unparse(data);
    } 
    else if(activeTab == 4){
      data.promotype.push({Category: "Copyright 2025. Meaningful Vision . All Rights Reserved. Proprietary and Confidential"});
      // console.log("data.promotype:::::", data.promotype);
      data.promotype.push({Category: "Property of Meaningful Vision and it's Affiliates. Licensed for Use by Meaningful Vision Clients Only."});

      const csvData = [];
      
      const products = data.Products;
      const categories = Object.keys(data.promotype[0]);

      categories.forEach((category) => {
          const row = { Category: category };
          data.promotype[0][category].forEach((value, index) => {
              row[products[index]] = value;
          });
          csvData.push(row);
      });

      csvData.push({Category: "Copyright 2025. Meaningful Vision . All Rights Reserved. Proprietary and Confidential"});
      csvData.push({Category: "Property of Meaningful Vision and it's Affiliates. Licensed for Use by Meaningful Vision Clients Only."});

      var csv = PapaParse.unparse(csvData);
    }
    
    else if(activeTab == 1 || activeTab == 2){

      
      data.push({ Chain: "Copyright 2025. Meaningful Vision . All Rights Reserved. Proprietary and Confidential" });
      data.push({ Chain: "Property of Meaningful Vision and it's Affiliates. Licensed for Use by Meaningful Vision Clients Only." });
  

        var csv = PapaParse.unparse(data);
    }

    var csvDataBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = window.URL.createObjectURL(csvDataBlob);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${Label[activeTab]}.csv`);
    tempLink.click();
};
const openNotification = (placement) => {
    api.info({
      message: `Downloading`,
      description: "While your file is being retrieved, feel free to continue using the tool.",
      placement,
    });
  };
  return (
    <>
      <Row className='mb-2'>
        <Col span={6} className='p-1'>
          <span className='bg-white text-dark p-2 rounded'>
            <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
              Products
            </span>{" "}
            <span style={{ color: "#1DB9DE" }} className='fw-bold'>
              {" "}
              |
            </span>{" "}
            <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
              Table
            </span>
          </span>
        </Col>
        <Col span={13} className='text-center'></Col>
        <Col span={5} className='p-1 text-center'>
          {" "}
          <Button onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
            Filters
          </Button>
          {" "}
          <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                // loading={loadingExport}
                onClick={() => {
                 getDownloadData(productData);
                }}
              ></Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Space size={[0, "small"]} wrap>
          {(activeTab == 2 || activeTab == 1) && (
            <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {(activeTab == 3 || activeTab == 4) && (
            <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales : Timescales}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {Timescales && Timescales.length > 0 ? Timescales : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          <Tooltip
            placement='bottomLeft'
            title={
              MarketSegment && MarketSegment.length > 0
                ? MarketSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Segment:
              {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              CompetitiveSet && CompetitiveSet.length > 0
                ? CompetitiveSet.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Competitive:
              {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          {activeTab != 4 && (
            <Tooltip
              placement='bottomLeft'
              title={
                Category && Category.length > 0
                  ? Category.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Category:
                {Category && Category.length > 0 ? `${Category[0]}  ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab != 4 && (
            <Tooltip
              placement='bottomLeft'
              title={
                ProteinType && ProteinType.length > 0
                  ? ProteinType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                ProteinType:
                {ProteinType && ProteinType.length > 0
                  ? `${ProteinType[0]}`
                  : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
           <Tooltip
            placement='bottomLeft'
            title={
              Offer && Offer.length > 0
                ? Offer.map((e,index) => {
                    return <li key={index}>{e === 1 ? "LTO" : "NON LTO"}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Offer:
              {Offer && Offer.length > 0 ? `${Offer[0]==1?" LTO":" NOT LTO"} ` : "Not selected"}{" "}

            </Tag>
          </Tooltip>
          
        </Space>
      </Row>

      {activeTab && ( <><Tabs
       defaultActiveKey={activeTab}
       activeKey={activeTab}
        className='activetabcolor'
        onChange={onChangeTab}
        items={new Array(4).fill(null).map((_, i) => {
          return {
            label: `${Label[i + 1]}`,
            key: i + 1,
            children: <>{activeTab == 3 && props && (
              <div className='text-center mb-3 mt-3'>
                <span className='p-2'>{"Active"}</span>
      
                <Switch onChange={onChangeSwitch} className='square-switch' defaultChecked={switchFlag} />
                <span className='p-2'>{"New"}</span>
              </div>
            )}
            {activeTab == 4 && <StackedBarChart data={productData} />}
            {activeTab == 3 && <Bar productData={productData} setActiveTab={setActiveTab} filterValues1={JSON.parse(JSON.stringify(filterValues))} setCalApi={setCalApi} calApi={calApi} switchFlag={switchFlag}/>}
            {(activeTab == 1 || activeTab == 2) && (
              <>
                {" "}
                <Table
                  columns={columns}
                  dataSource={productData.length > 0 ? productData : []}
                  pagination={false}
                  loading={loader1}
                  scroll={{
                    x: 800,
                    y: "60vh",
                  }}
                  onChange={(val, filter, sorter, extra) => {
                    setSorter({
                      ...sorter,
                    });
                  }}
                />
                <div className='d-flex justify-content-end p-4'>
                  <Pagination
                    current={currentPage}
                    defaultCurrent={currentPage}
                    total={totalPage}
                    pageSize={50}
                    onChange={(e) => {
                      setCurrentPage(e);
                    }}
                    showSizeChanger={false}
                  />
                </div>
              </>
            )}</>,
          };
        })}
      />
      

      </>
    )}

      <Modal
        open={modalflag}
        justifyContent='center'
        destroyOnClose
        onCancel={(e) => {
          setModalFlag(false);
        }}
        footer={false}
        size='large'
        width={600}
        height={600}
        title={<div className='text-left fs-6'>{ImageName}</div>}
      >
        <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>
          <img src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${img}`} width='300' height='300px' />
        </div>{" "}
      </Modal>
      <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={"Product"} activeTabProductSnapshot={activeTab} dashboard={"Products"}/>
    </>
  );
};

export default Snapshot;
